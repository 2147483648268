import getStickyHeaderHeight from "./getStickyHeaderHeight.js";
import $ from "jquery";

export default function stickyHeader(stickyElement) {
    let stickyEl = document.querySelectorAll(stickyElement)[0],
        scrollTop = window.pageYOffset || document.documentElement.scrollTop,
        headerHeight = 0;

        if(typeof stickyEl === "undefined") {
            return;
        } 

        let stickyTop = stickyEl.offsetTop + stickyEl.offsetHeight;

        headerHeight = getStickyHeaderHeight();
        if (!document.body.classList.contains('mobile-nav-open') && !document.body.classList.contains('blank-layout')) {
            document.body.style.marginTop = headerHeight + 'px';
            //document.body.style.marginTop = '81px';
            console.log('document.body.style.marginTop=' + headerHeight);
            stickyEl.classList.add("sw-headerpane--sticky");
        }

    window.addEventListener('scroll', function() {
        if (window.pageYOffset >= headerHeight && !document.body.classList.contains('mobile-nav-open') && document.body.classList.contains('is-homepage')) {  
            // if a mobile user starts to scroll remove hover menu 
            document.body.classList.remove('is-homepage');
        }
    });

    // document.Ready is too early, need to wait for sitecore to inject all its stuff
    window.onload= function () {
        // Shift the header down past the height of the Experience Editor Panel    
        if (document.body.classList.contains('is-page-editor') ||
            document.body.classList.contains('is-page-preview')) {

            var target = document.getElementById('scCrossPiece');
            if(target != null){
                // first time initialize
                $('.sw-headerpane').css('top', target.style.height);
                
                // constant observer for open close of the experience editor
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutationRecord) {
                    $('.sw-headerpane').css('top', target.style.height);
                    });
                });    
                observer.observe(target, { attributes : true, attributeFilter : ['style'] });
            }
            else{
                waitForScCrossPiece();
            }
        }    
    };
 

      function waitForScCrossPiece(){
        var target = document.getElementById('scCrossPiece');
        if(target != null){
            // first time initialize
            $('.sw-headerpane').css('top', target.style.height);
            
            // constant observer for open close of the experience editor
            var observer = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutationRecord) {
                $('.sw-headerpane').css('top', target.style.height);
                });
            });    
            observer.observe(target, { attributes : true, attributeFilter : ['style'] });
        }
        else{
            window.setTimeout(function(){
                waitForScCrossPiece();}, 500);
        }
      }
    // function handleStickyHeader() {
    //     if (window.pageYOffset >= headerHeight && !document.body.classList.contains('mobile-nav-open')) {     

    //         document.body.style.marginTop = headerHeight + 'px';
    //         stickyEl.classList.add("sw-headerpane--sticky");
    //         document.body.classList.remove('is-homepage');
    //     } else {
    //         document.body.removeAttribute('style');
    //         stickyEl.classList.remove("sw-headerpane--sticky");
    //     }
    // }

}