import debounce from 'lodash.debounce';
import forEach from '../helpers/forEach.js';
import $ from "jquery";

export default function getStickyHeaderHeight() {
    let stickyHeaderHeight = 0;

    function calculateStickyHeaderHeight() {
        let stickyHeader = document.querySelectorAll('.sw-headerpane')[0],
            euCookie = document.querySelectorAll('.sw-container--eucookie')[0],
            quickNav = document.querySelectorAll('.sw-quicknav')[0],
            navSection = document.querySelectorAll('.sw-container--header')[0];

        if (typeof stickyHeader === "undefined") {
            return stickyHeaderHeight;
        }

        let headerChildrenContainers = stickyHeader.querySelectorAll('.sw-container');

        if (document.body.classList.contains('has-hero')) {
            if (window.outerWidth < 1024) {
                stickyHeaderHeight = stickyHeader.offsetHeight; //47 being mobile quicknav's height
            } else {
                stickyHeaderHeight = stickyHeader.offsetHeight + navSection.offsetHeight;
            }
        } else {
            if (window.outerWidth < 1024) {
                stickyHeaderHeight = stickyHeader.offsetHeight; // sstickyHeader.offsetHeight + 47;
            } else {
                stickyHeaderHeight = stickyHeader.offsetHeight// + navSection.offsetHeight;
            }
        }
        console.log('Xstickyheight: ' + stickyHeaderHeight); 
    }

    calculateStickyHeaderHeight();
    window.addEventListener("resize", debounce(function () {
        calculateStickyHeaderHeight();
    }, 1000));


    window.addEventListener("scroll", debounce(function () {
        calculateStickyHeaderHeight();
    }, 1000));

    window.addEventListener("load", function () {
        calculateStickyHeaderHeight();
    });

    return stickyHeaderHeight;
}