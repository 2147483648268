import $ from "jquery";

export default function nutritionGrid() {
    let _nutGridDataEndpoint = "/SubwayV2/Data/RenderNutritionGrid.aspx";
    let $nutGrid = $('#sw-main .js-nutrition-grid--v2');
    let _cultureCode = $nutGrid.find('.js-sw-tabs').data('culture-code');
    let $tabs = $nutGrid.find('.js-sw-nutrition-grid-tab');

    //initial load - load all 3 tabs with table data
    $tabs.each(function(index, value) {
        var event = new jQuery.Event($(value));
        event.isInitialLoad = true;
        event.currentTarget = value;
        refreshNutGrid(event);
    });

    function refreshNutGrid(event) {
        let wasInitialLoad = false;
        if(event.isInitialLoad !== "undefined") {
            wasInitialLoad = event.isInitialLoad;
        }

        //if current target is a nut. grid tab, use it as selectedTab. Else, attempt to drill up to find the selected tab
        var $selectedTab = $(event.currentTarget);
        if(!$selectedTab.hasClass('js-sw-nutrition-grid-tab')) {
            $selectedTab = $tabs.filter('[aria-selected="true"]');
        }

        $('.nutritionClass').animate({ scrollTop: 0 }, 'normal');//probably good to keep this, to scroll to the top of the grid container?  necessary??

        let $tabPanel =  getSelectedGridContainer($selectedTab);
        let selCategory = $tabPanel.find('.js-sw-nutrition-grid__category').val();
        let _fullEndpoint = _nutGridDataEndpoint + "?Page=" + $selectedTab.data('navigate-page-id') + "&cultureCode=" + _cultureCode + "&selectedCategoryId=" + selCategory;

        let $amountTypeSelected = $tabPanel.find('input[name^=Amount-]:checked');
        if($amountTypeSelected.length) {
            _fullEndpoint += "&amountType=" + $amountTypeSelected.val();
        }

        if(wasInitialLoad) {
            _fullEndpoint += "&initialize";
        }

        $.ajax({
            url: _fullEndpoint,
            type: 'GET',
            contentType: "application/json",
            dataType: 'html',
            cache: false,
        })
            .done(function (data) {
                let $tabPanel = getSelectedGridContainer($selectedTab);
                $tabPanel.html(data);
                attachHandlers($tabPanel);
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                console.log("JSON Error: " + textStatus);
                let $tabPanel = getSelectedGridContainer($selectedTab);
                $tabPanel.html('<div class="error">Error retrieving data</div>');
            })
        ;
    }

    function getSelectedGridContainer($selectedTab) {
        return $('#' + $selectedTab.data('navigate-page-id') + '-nutritionDiv');
    }

    function attachHandlers($tabPanel) {
        //category picker
        $tabPanel.find('.js-sw-nutrition-grid__category').on('change', event => refreshNutGrid(event));
        //serving size, if enabled
        if($tabPanel.find('input[name^=Amount-]').length) {
            $tabPanel.find('input[name^=Amount-]').on('change', event => refreshNutGrid(event));
        }
        // data cell mouseovers
        $tabPanel.find('td.info, th.name').hover(function() {
            var $this = $(this);
            var $closestRow = $this.closest('tr');
            //don't do focus and rollover styles for the ingredient heading rows
            var isCategoryRow = $closestRow.hasClass('category-row');

            if(!isCategoryRow) {
                $closestRow.addClass('infoRowOver');
            }

            if($this.hasClass('info')) {
                var t = parseInt($(this).index()) + 1;

                if(!isCategoryRow) {
                    $this.addClass('infoFocus');
                }
                $('td:nth-child(' + t + '):not(.infoFocus)', $this.closest('table')).addClass('infoRowOver');
                //header stuff
                var $theHeader = $('#' + $this.attr('headers').split(' ')[0]);
                $theHeader.addClass('headOver');
                var $headerImage = $theHeader.find('img');
                $headerImage.attr('src', $headerImage.attr('src').replace('.png', '-roll.png'))
            }
            else {
                if(!isCategoryRow) {
                    $closestRow.removeClass('name').addClass('nameFocus');
                }
            }
        },
        function() {
            var $this = $(this);
            var $closestRow = $this.closest('tr');
            var isCategoryRow = $closestRow.hasClass('category-row');

            if(!isCategoryRow) {
                $closestRow.removeClass('infoRowOver');
            }

            if($this.hasClass('info')) {
                var t = parseInt($(this).index()) + 1;

                if(!isCategoryRow) {
                    $this.removeClass('infoFocus');
                }
                $('td:nth-child(' + t + ')', $this.closest('table')).removeClass('infoRowOver');
                //header stuff
                var $theHeader = $('#' + $this.attr('headers').split(' ')[0]);
                $theHeader.removeClass('headOver');
                var $headerImage = $theHeader.find('img');
                $headerImage.attr('src', $headerImage.attr('src').replace('-roll.png', '.png'))
                ;
            }
            else {
                if(!isCategoryRow) {
                    $closestRow.removeClass('nameFocus').addClass('name');
                }
            }
        });
    }

}