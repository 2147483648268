import $ from 'jquery';

export default function languageSelector(container) {
    const $toggleButton = $(container).find('.sw-eyebrow__language-selector'),
          $languageOption = $(container).find('.sw-eyebrow__language-option'),
          $languageDropdown = $(container).find('.sw-eyebrow__language-dropdown'),
          $languageCurrent = $(container).find('.sw-eyebrow__language-current');

          // initializer
          $languageDropdown.attr('aria-hidden', (!$(container).hasClass('is-open')).toString());

    $toggleButton.on('click', function(e) {
        e.preventDefault();

        if($(container).hasClass('is-open')) {
            $(container).removeClass('is-open');
            $toggleButton.attr("aria-expanded", "false");
            $languageDropdown.attr('aria-hidden', 'true');
        } else {
            if (typeof(_satellite) !== "undefined") {
                _satellite.track('Nav Click Event Language Selection');
            }
            $(container).addClass('is-open');
            $toggleButton.attr("aria-expanded", "true");
            $languageDropdown.attr('aria-hidden', 'false');

        }

        if($('body').hasClass('language-dd-on')) {
            $('body').removeClass('language-dd-on');
        } else {
            $('body').addClass('language-dd-on');
        }
    });

    $(document).on('click', function(e) {
        if($(container) !== e.target && !$(container).has(e.target).length){
            if($(container).hasClass('is-open')) {
            $toggleButton.attr("aria-expanded", "false");
            $(container).removeClass('is-open');
            }

            if($('body').hasClass('language-dd-on')) {
                $('body').removeClass('language-dd-on');
            }
        }
    });
}