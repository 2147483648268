import $ from 'jquery';
import Cookies from 'js-cookie';

export default function toggleContrast(toggleButton) {
    if(Cookies.get('high-contrast') === 'on') {
        if(!$('body').hasClass('contrast-on')) {
            $('body').addClass('contrast-on');
            $(toggleButton).attr("aria-pressed", "true");
        }
    }

    $(toggleButton).on('click', function(e) {
        e.preventDefault();
        if($('body').hasClass('contrast-on')) {
            $('body').removeClass('contrast-on');
            Cookies.remove('high-contrast');
            $(toggleButton).attr("aria-pressed", "false");
        } else {
            if (typeof(_satellite) !== "undefined") {
                _satellite.track('Nav Click Event High Contrast');
            }
            $('body').addClass('contrast-on');
            Cookies.set('high-contrast', 'on');
            $(toggleButton).attr("aria-pressed", "true");
        }
    });
}