import $ from 'jquery';

export default function activeNavItem() {

    try {
        const currentNavItem = subway_site_config.currentNavItem,
            currentUrlPathname = window.location.pathname,
            $mainNavItem = $('.js-sw-mainnav__item'),
            activeClassName = 'is-current'
        ;

        //This should add the active menu class for submenu items and the parent menus
        //ALSO, make sure that we don't just highlight some section with a busted link when we are at the homepage.  don't do any highlighting if we just have domain and culture code.
        let rex = /^\/[a-zA-Z]{2}\-[a-zA-Z]{2}(\/|)$/;
        if(!rex.test(currentUrlPathname)) {
            let $currentUrlLink = $('.js-sw-mainnav .sw-mainnav__list').find('a[href|="' + currentUrlPathname + '"]');
            
            // If more than one match, compare currentNavItem to the panel's title (hidden element), and highlight the one that matches
            // if we do not find a match...just pick your favorite one, because I am out of ideas.
            let $realMatch = null;
            if($currentUrlLink.length === 1) {
                $realMatch = $currentUrlLink;
            }
            else if($currentUrlLink.length > 1) {
                const $filtered = $currentUrlLink.filter(function(index) {
                    const $matched = $(this).closest('.sw-mainnav__dropdown').find('.sw-mainnav__dropdown-title a[class="js-sw-mainnav__allowactive"]');
                    return $matched.attr('href') === currentNavItem;
                });

                if($filtered.length === 1) {
                    $realMatch = $filtered;
                }
                else {
                    $realMatch = $currentUrlLink.filter(function(index) {
                        //highlight the first link that is NOT a child of a (hidden) menu panel title element.  We used to show the panel headings, but haven't for a long time.
                        return $(this).parent('.sw-mainnav__dropdown-title').length === 0;
                    });
                    $realMatch = $realMatch.length > 1 ? $realMatch.first()  : $realMatch;
                }
            }

            if($realMatch !== null && $realMatch.length > 0) {
                $realMatch.addClass(activeClassName);
                $realMatch.closest($mainNavItem).addClass(activeClassName);
            }
        }
    } catch(err) {
        console.log(err);
    }
}