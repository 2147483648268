import $ from 'jquery';
window.favoriteStoreID = null;
window.recentStoreID = null;
window.guestId = null;
window.guestCountry = null;
window.loyaltyId = null;
window.isLoggedin = false;
window.encryptedToken = null;
window.useLusrFlag = null;


export default function forLoggedInUser() {
    let _loggedInEndpoint = $('body').data('logged-in-end-point');
    let useLusr = $('body').data('variable');
    window.useLusrFlag = (useLusr == 'True')
    //alert("useLusrFlag1=" + window.useLusrFlag);
    //let isLoggedin = false;
    isLoggedin = false;

    // toggle for the loggedin user menu
    $('.js-sw-eyebrow__navlink--user').on('click', function (e) {
        e.preventDefault();
        toggleUserMenu($(this), $('.js-sw-eyebrow__usermenu'));
    });

    // toggle for the mobile sign in/sign up menu
    $('.js-sw-eyebrow__navlink--userloginsignup').on('click', function (e) {
        e.preventDefault();
        toggleUserMenu($(this), $('.js-sw-eyebrow__userloginsignup-menu'));
    });

    // do not do the call if the information is in a cookie 
    let cookieValue = getCookieValue('UserProfileCacheKey');

    if (cookieValue !== '') {
        let data = JSON.parse(cookieValue);
        //alert("flag=" + useLusrFlag);
        if (window.useLusrFlag) {
            performLoginCheck_new(data);
        }
        else {
            performLoginCheck(data);
        }

        toggleLoginSection(data.isLoggedIn);
        return;
    }

    //dump out of this before the AJAX call if there is not logged in endpoint (ex: for Navigation-only eyebrow menu)
    if (typeof _loggedInEndpoint !== "undefined") {
        $.ajax({
            url: _loggedInEndpoint,
            type: 'GET',
            dataType: 'json',
        })
            .done(function (data) {
                isLoggedin = data.isLoggedIn;
                //alert("flag=" + useLusrFlag);
                if (useLusrFlag) {
                    performLoginCheck_new(data);
                }
                else {
                    performLoginCheck(data);
                }

            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                isLoggedin = false;
                console.log('A problem occured: ', errorThrown);
            })
            .always(function () {
                toggleLoginSection(isLoggedin);
            });
    }

    function toggleUserMenu($actor, $menu) {
        let $parent = $actor.closest('.js-sw-eyebrow__navitem--authentication');

        if ($parent.hasClass('usermenu-open')) {
            closeUserMenu($parent, $actor, $menu);
        } else {
            openUserMenu($parent, $actor, $menu);
        }
    }

    function openUserMenu($parent, $actor, $menu) {
        $parent.addClass('usermenu-open');
        $actor.attr('aria-expanded', 'true');
        $menu.attr('aria-hidden', 'false');
    }

    function closeUserMenu($parent, $actor = null, $menu = null) {
        $parent.removeClass('usermenu-open');

        // If no actor or menu were passed in, assume that the caller wants to close all open user menus, and close them all
        if ($actor !== null && $menu !== null) {
            $actor.attr('aria-expanded', 'false');
            $menu.attr('aria-hidden', 'true');
        }
        else {
            $parent.find('[aria-expanded=true]').attr('aria-expanded', 'false');
            $parent.find('[aria-hidden=false]').attr('aria-hidden', 'true');
        }
    }

    function getCookieValue(cookieName) {
        let cookieString = '; ' + document.cookie;
        let cookieArray = cookieString.split('; ' + cookieName + '=');
        return !!(cookieArray.length - 1) ? cookieArray.pop().split(';').shift() : '';
    }

    function trackLoginStatus(data) {
        try {
            if (fwhtrk != null) {
                if (data.isLoggedIn == true)
                    fwhtrk.user.loginStatus = "logged-in";
                else
                    fwhtrk.user.loginStatus = "logged-out";

                fwhtrk.loginStatus = fwhtrk.user.loginStatus;
            }
        } catch (e) {
            fwhtrk.errorMessage = "Error getting logged-on status: " + e.errorMessage;
        }
    }

    function trackGuestLoyaltyId(data) {
        try {
            if (fwhtrk != null) {
                fwhtrk.user.loyaltyID = loyaltyId;
                fwhtrk.user.guestID = guestId;
                fwhtrk.user.guestCountry = guestCountry;
                fwhtrk.guestCountry = guestCountry;
            }
        } catch (e) {
            fwhtrk.errorMessage = e.errorMessage;
        }
    }

    /*
        TW 121820 - commented out the following code.  Thats not how we should be getting guest country
    */
    /* track GuestCountry 
    function trackGuestCountry(data) {
        try {
            if (fwhtrk != null) {
				 $(document).ready(function () {
				var pageURL = $(location).attr("href");
				var urlArr = pageURL.split('/');
				console.log(urlArr[3].toString());
				let guestLang= urlArr[3].toString();
				var guestCntry = (urlArr.length > 0 ) ? guestLang.split('-') : null;
				//check _claim token return guestCountry if not get country from loggedin user's browser
                     fwhtrk.guestCountry = (guestCountry == null) ? guestCntry[1].toString().toLowerCase() : guestCountry.toLowerCase();
             });
               
          }
        } catch (e) {
            fwhtrk.errorMessage = e.errorMessage;
        }
    }
    */

    function performLoginCheck_new(data) {
        isLoggedin = data.isLoggedIn;
        if (data.isLoggedIn == true) {
            guestId = data.guestId;
            guestCountry = data.guestCountry;
            loyaltyId = data.loyaltyId;
            encryptedToken = data.encryptedToken;
            //alert("encryptedToken ="+encryptedToken);
            if (typeof locator !== "undefined" && (encryptedToken != null) && encryptedToken != '') {
                locator.setClientToken(encryptedToken);
            }
            populateLoggedInUserInfo(data);
            trackLoginStatus(data);
            trackGuestLoyaltyId(data);
            //trackGuestCountry(data);  //TW commented out 121820

        }
    }

    function performLoginCheck(data) {
        isLoggedin = data.isLoggedIn;
        if (data.isLoggedIn == true) {
            guestId = data.guestId;
            guestCountry = data.guestCountry;
            loyaltyId = data.loyaltyId;
            window.favoriteStoreID = data.favoriteStoreId;
            window.recentStoreID = data.recentStoreId;
            //alert("favoriteStoreID=" + favoriteStoreID);
            //alert("recentStoreID=" + recentStoreID);
            if (typeof locator !== "undefined" && (window.favoriteStoreID !== null && window.favoriteStoreID != '')) {
                //alert("add favorite store");
                locator.addFavoriteStore(window.favoriteStoreID, 0);

            }

            if (typeof locator !== "undefined" && (window.recentStoreID !== null && window.recentStoreID != '')) {
                //alert("add favorite store");
                locator.addRecentStore(window.recentStoreID, 0);
            }
            populateLoggedInUserInfo(data);
            trackLoginStatus(data);
            trackGuestLoyaltyId(data);
            //trackGuestCountry(data); //TW commented out 121820

        }
    }

    $(document).on('click', function (e) {
        var targetEle = $('.js-sw-eyebrow__navitem--authentication');
        if (targetEle !== e.target && !targetEle.has(e.target).length) {
            if (targetEle.hasClass('usermenu-open')) {
                closeUserMenu(targetEle);
            }
        }
    });

    //call external log in process if one is defined
    $('.lnkLogin').on('click', function (event) {
        if (typeof initiateLogin === "function") {
            event.preventDefault(); //This prevents the control's Click event from firing
            initiateLogin(event, $(this));
        }
    });

    //call external log out process if one is defined
    $('.lnkLogout').on('click', function (event) {
        if (typeof initiateLogout === "function") {
            event.preventDefault(); //This prevents the control's Click event from firing
            initiateLogout(event, $(this));
        }
    });
};

export function toggleLoginSection(isLoggedIn) {
    let $loginSection = $('.js-sw-eyebrow__navlink--userlogin'),
        $logoutSection = $('.js-sw-eyebrow__navlink--user'),
        $loginMobileMenuSection = $('.js-sw-eyebrow__userloginsignup-menu'),
        $logoutMenuSection = $('.js-sw-eyebrow__usermenu'),
        $loggedInOnlySections = $('.js-sw-section--loggedinonly'),
        $loggedOutOnlySections = $('.js-sw-section--loggedoutonly')
        ;

    if (isLoggedIn === true) {
        $('body').addClass('user-logged-in');

        $logoutSection.show();
        if ($loginSection.length) {
            $loginSection.hide();
        }
        if ($loginMobileMenuSection.length) {
            $loginMobileMenuSection.hide();
        }
        $logoutMenuSection.show();
        $loggedInOnlySections.show();
    }
    else {
        if ($('body').hasClass('user-logged-in')) {
            $('body').removeClass('user-logged-in');
        }

        $logoutSection.hide();
        if ($loginSection.length) {
            $loginSection.show();
        }
        if ($loginMobileMenuSection.length) {
            $loginMobileMenuSection.show();
        }
        $logoutMenuSection.hide();
        $loggedOutOnlySections.show();
    }

    try {
        // retrigger image lazy load - new sections may be visible
        window.myLazyload1.update();
        window.myLazyload2.update();
    } catch (err) {
        console.log("lazyload refresh/update failed");
    }
};

export function populateLoggedInUserInfo(userData) {
    /*
        userData is expected to be an object, with at least the following properties:
        - displayName:  user's name
        - tokens:       user's token count
        - rewards:        user's rewards in dollars
    */
    if (typeof userData !== "object" ||
        typeof userData.displayName === "undefined" ||
        typeof userData.tokens === "undefined" ||
        typeof userData.rewards === "undefined") {

        console.log('missing or invalid user data');
        return;
    }

    let $tokenPane = $('.js-sw-eyebrow__navitem--authentication').find('.sw-eyebrow__tokenpane'),
        $tokens = $tokenPane.find('.sw-eyebrow__token'),
        $tokenRewards = $('.js-sw-rewards__token'),
        $greeting = $('.js-sw-eyebrow__greeting')
        ;

    // allow for multiple token texts, but only display the correct one for the token count
    $tokens.each(function (index) {
        var $this = $(this);
        $this.text($this.text().replace("{tokens}", userData.tokens));

        if (userData.tokens > 1) {
            if ($this.hasClass('token-multiple')) {
                $this.show();
            }
        } else {
            if ($this.hasClass('token-single')) {
                $this.show();
            }
        }
    });

    $tokenRewards.text('$' + userData.rewards);

    var currentGreetingText = $greeting.text();
    $greeting.text(currentGreetingText.replace("{name}", userData.displayName));
};