import $ from "jquery";
import getStickyHeaderHeight from "./getStickyHeaderHeight.js";

export default function anchoredDisclaimer() {
  let anchorElements = Array.from(
      document.querySelectorAll(".js-sw-disclaimer--anchor")
    ),
    keycodes = { SPACE: 32, ENTER: 13 },
    headerHeight = 0,
    $hasLink = $(".has-link"),
    $isEE = $(".is-page-editor");

  window.onscroll = function() {
    headerHeight = getStickyHeaderHeight();
  };

  if (anchorElements.length) {
    anchorElements.forEach(function(el, index) {
      let anchorId = el.getAttribute("data-href");

      el.onkeydown = function(event) {
        navigateAnchorLink(event, anchorId);
      };

      el.onclick = function(event) {
        navigateAnchorLink(event, anchorId);
      };
    });
  }
  if ($isEE.length == 0) {
      $hasLink.on("click keydown", function(e) {
          if (e.type === "click" || e.keyCode === keycodes.ENTER) {
              var $mainLink = $(this).find(".js-sw-imgblock__mainlink");
              if ($mainLink.length > 0) {
                  if (!$(e.target).hasClass("sw-disclaimer--anchor")) {
                      var url = $mainLink.attr("href");
                      var location = $mainLink.attr("target") ? $mainLink.attr("target") : "_self";
                      window.open(url, location);
                      return false;
                  } else {
                      navigateAnchorLink(event, $(e.target).attr("data-href"));
                  }
              } else {
                  return;
              }
          }
      });
  }

  //for cta panels, need to add focus styles to the parent of the link if there is no button
  var $hiddenCTAPanelLinks = $hasLink.find('.js-sw-imgblock__mainlink.accessibility--hidden');
  if($hiddenCTAPanelLinks.length > 0) {
    $hiddenCTAPanelLinks.each(function(index) {
      var $this = $(this);
      var $containingCTAPanel = $this.closest('.sw-up__col--content-block');
      if($containingCTAPanel.length > 0) {
        $this
          .focus(function() {
            $containingCTAPanel.addClass('cta-parent-focus');
          })
          .blur(function() {
            $containingCTAPanel.removeClass('cta-parent-focus');
          });
      }
    });
  }
  
  //handles clicks and keydowns on anchor span tag
  function navigateAnchorLink(evt, anchorId) {
    if (
      evt.type === "click" ||
      evt.keyCode === keycodes.SPACE ||
      evt.keyCode === keycodes.ENTER
    ) {
      evt.preventDefault();

      window.scrollTo({
        left: 0,
        top: calculateScrollTop(anchorId),
        behavior: "smooth"
      });
    }
  }

    function calculateScrollTop(anchorId) {
        let scrollToEl = document.getElementById(anchorId),
            scrollToRect = scrollToEl.getBoundingClientRect(),
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return scrollToRect.top + scrollTop - headerHeight - ~~(window.innerHeight/2); 
    }

    window.calculateScrollTop = calculateScrollTop;
}
