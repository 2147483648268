/* NPM modules imports */
import $ from "jquery";
import svg4everybody from 'svg4everybody';
import debounce from 'lodash.debounce';
import 'objectFitPolyfill';
import smoothscroll from 'smoothscroll-polyfill';
/* Site Helpers imports */
import breakpoint from "./helpers/breakpoints.js";
import downloadAppSwipe from "./modules/downloadApp.js";
/* Site Module imports */
import languageSelector from "./modules/languageSelector.js";
import toggleContrast from "./modules/toggleContrast.js";
import mainNav from "./modules/mainNav.js";
import mobileNav from "./modules/mobileNav.js";
import dialogManager from "./modules/dialogManager.js";
import homePageModal from "./modules/homePageModal.js";
import moveItems from "./modules/moveItems.js";
import forLoggedInUser from "./modules/loggedin.js"
import {toggleLoginSection} from "./modules/loggedin.js";
import {populateLoggedInUserInfo} from "./modules/loggedin.js";
import { ImageSlider } from "./modules/ImageSlider";
import getTheApp from "./modules/getTheApp.js";
import getTheAppBanner from "./modules/getTheAppBanner.js";
import getAppLink from "./modules/getAppLink.js";
import signup from "./modules/signup.js";
import alert from "./modules/alert.js";
import tabs from "./modules/tabs.js";
import accordion from "./modules/accordion.js";
import stopPageJumpForHashUrl from "./modules/stopPageJumpForHashUrl.js";
import flipper from "./modules/flipper.js";
import backToTopButton from "./modules/backToTopButton.js";
import stickyHeader from "./modules/stickyHeader.js";
import anchoredDisclaimer from "./modules/anchoredDisclaimer.js";
import activeNavItem from "./modules/activeNavItem.js";
import {bgVideo} from "./modules/bgVideo.js";
import {loadPress} from "./modules/loadPress.js";
import {VideoPlayer} from "./modules/videoPlayer.js";
import {OpenModal} from "./modules/openModal.js";
import nutritionGrid from "./modules/nutritionGrid.js";
import touchSwipe from "./custom-vendor/jquery-touchswipe.js";

window.toggleLoginSection = toggleLoginSection;
window.populateLoggedInUserInfo = populateLoggedInUserInfo;
window.sliders = [];

if (typeof LazyLoad != 'undefined') {
myLazyload1 = new LazyLoad({
    callback_set: function (el) {
        picturefill({ reevaluate: true });
        objectFitPolyfill();
        homePageModal();
    }
});

}


//Trigger breakpoint on resize and page load
$(window).resize(function () {
    //breakpoint.refreshValue();
}).resize();

/* Function Calls */
svg4everybody();

// kick off the polyfill!
smoothscroll.polyfill();

if($('.js-sw-eyebrow__language').length) {
    languageSelector('.js-sw-eyebrow__language');
}

if($('.js-sw-contrast').length) {     
    toggleContrast('.js-sw-contrast');
}

if($('.js-sw-mainnav__item').length) {
    mainNav('.js-sw-mainnav__item');
}

if($('.js-sw-hamburger').length) {
    mobileNav('.js-sw-hamburger');
}

if($('.js-sw-signup').length) {
    $('.js-sw-signup').each(function(){
        signup($(this));
    });
}




activeNavItem();
moveItems();
forLoggedInUser();
getTheApp();
getTheAppBanner();
getAppLink();
alert();
stopPageJumpForHashUrl();
flipper();
backToTopButton();
downloadAppSwipe();
//dialogManager();


if($('.js-sw-tabs').length) {
    tabs();
}

if($('.js-sw-accordion').length) {
    accordion();   
}

if($('.js-nutrition-grid--v2').length) {
    nutritionGrid();
}

stickyHeader('.sw-headerpane');
anchoredDisclaimer();

// Slideshows
// do not intialize slideshows inside
// page editor

const slideshows = $.makeArray($(".js-sw-hero__slider")).map( el => {
    let autoPlay,
        rtlCase;
    if ($("body").hasClass("is-page-editor")) {
        autoPlay = false;
    } else {
        autoPlay = true;
    }
    if($('html').attr('dir') === "rtl") {
        rtlCase = true;
    } else {
        rtlCase = false;
    }
    if($(el).find('.sw-hero__slide').length > 1) {
        var imageSlider = new ImageSlider($(el), '.sw-hero__slide', { autoplay: autoPlay, rtl: rtlCase });
        window.sliders.push(imageSlider);
    } else if ($(el).find('.sw-hero__slide').length === 1){
        // still need to initialize the focus outline if it exists 
        let currentOutlineStyle = $(el).find('.sw-hero__slide').first().data("outline");

        if (typeof currentOutlineStyle !== "undefined") {
            $(el).addClass(currentOutlineStyle);
        }
    }
});
const bgvideo = $.makeArray($(".js-sw-video--bg")).map( el => {
    return new bgVideo($(el));
})
const LoadPress = $.makeArray($(".js-sw-press__load-more")).map( el => {
    return new loadPress($(el));
})
/** HACK: objectFitPolyfill
 *  1. using debounce from lodash
 *  2. to address image getting weird size and 
 *  3. positioned differently on IE when the source set changed
 *  4. objectFitPolyfill() is being called twice on resize
 *  5. One with deboucne (here below) and the other without in the src polyfill file
 *--------------------------------------*/
window.addEventListener("resize", debounce(function() {
    objectFitPolyfill();
}, 1000));
if($(".js-sw-modal-btn").length){
    let counter = 0;
    const openModal = $.makeArray($(".js-sw-modal-btn")).map(el => {
        counter++;
        var $el = $(el);
        $el.attr('id', 'js-sw-modal-btn__' + counter);
        return new OpenModal($el);
    })
}
if($(".js-sw-video__player").length){
    const videoPlayer = $.makeArray($(".js-sw-video__player")).map(el => {
        return new VideoPlayer($(el));
    })
}


//launch-locator-catering
$(document).ready(function () {
	if ($(".launch-locator-catering") != null) {
			$(".launch-locator-catering").on("click", function () {launchLocator('HasCatering');});
	}
});

// $(document).ready(function () {
// 	console.log('docready homepagemodal');
//     if($('.homePageModal').length) {   
//         homePageModal();
//     }
// });


window.stopAllSliders = function (){
    $.each(window.sliders, function(i, slider){
        slider.stopSlider();
    })
  }

  window.startAllSliders = function (){
    $.each(window.sliders, function(i, slider){
        slider.stopSlider();
        slider.toggleSlider();
    })
  }
  