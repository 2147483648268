import $ from 'jquery';
import dialogManager from "./dialogManager.js";

export default function mainNav(el) {
    $(el).each(function() {
        const $self = $(this),
              $link = $self.find('.sw-mainnav__link'),
              $dropdown = $self.find('.sw-mainnav__dropdown'),
              $closeButton = $self.find('.sw-mainnav__dropdown-close');

        if($self.hasClass('has-dropdown')) {
            $link.on('click', function(e) {
                e.preventDefault();
                toggleDropdown($self);
                return false;
            });

            $closeButton.on('click', function(e) {
                closeDropdown($self);
            });
        }
    });

    function toggleDropdown(element) {
 
        if(element.hasClass('dropdown-open')) {            
            closeDropdown(element);
        } else {
            trackMenuClick(element);
            openDropdown(element);
        }

        if(element.siblings().hasClass('dropdown-open')) {
            closeDropdownSiblings(element);
        }
    }
 
    function openDropdown(element) {

        element.addClass('dropdown-open');
        element.find('[aria-expanded=false]').attr('aria-expanded', 'true');
        element.find('[aria-hidden=true]').attr('aria-hidden', 'false');

        $('body').addClass('dropdown-active');
		
		if($('body').hasClass('user-logged-in')) {
			element.find('.js-sw-section--loggedinonly').show();
			element.find('.js-sw-section--loggedoutonly').hide();
		}else{
			element.find('.js-sw-section--loggedinonly').hide();
			element.find('.js-sw-section--loggedoutonly').show();
        }
        

        
        var items = $("ul.sw-mainnav__list li.sw-mainnav__item");
        $(items).each(function(i,elem){
            if($(elem).hasClass("dropdown-open")===false){
                $(elem).addClass("sibling-open");
            }

        });


        
        //set focus on back button for mobile
       if($(document).find('.dropdown-open .backBtnMobile:visible').length>0)
        {  
            var dialog = new dialogManager();

            var modal=$('nav.sw-mainnav.js-sw-mainnav');
            var backBtn = $('li.has-dropdown.js-sw-mainnav__item.dropdown-open button.sw-mainnav__dropdown-close');
            var findAStore = $('a.sw-shopnav__trigger');
            dialog.createDialog(modal[0]);
            dialog.addSingleItem($("button.sw-hamburger")[0]);
            dialog.addSingleItem($('a.sw-logo')[0]);

            var login = $('button.js-sw-eyebrow__navlink--userloginsignup')[0];
            var loggedIn = $('button.js-sw-eyebrow__navlink--user')[0];
            
            if(login != null){ dialog.addSingleItem(login);}  
            if(loggedIn != null){ dialog.addSingleItem(loggedIn);} 
                
            window.setTimeout(function(){dialog.setInitialFocus(backBtn[0]);},300); 

            // $(document).find('.dropdown-open button.sw-mainnav__dropdown-close:visible').each(function(i,elem){       
            //     window.setTimeout(function(){$(elem).focus();},300);
            // });
                
        }
        
    }

    function closeDropdown(element) {

        var items = $("ul.sw-mainnav__list li.sw-mainnav__item");
        $(items).each(function(i,elem){
            $(elem).removeClass("sibling-open");
        });


        element.find('[aria-expanded=true]').focus();

        element.removeClass('dropdown-open');
        element.find('[aria-expanded=true]').attr('aria-expanded', 'false');
        element.find('[aria-hidden=false]').attr('aria-hidden', 'true');

        $('body').removeClass('dropdown-active');
        
		element.find('.js-sw-section--loggedinonly').hide();
		element.find('.js-sw-section--loggedoutonly').hide();
    }

    function closeDropdownSiblings(element) {
        element.siblings().removeClass('dropdown-open');
        element.siblings().find('[aria-expanded=true]').attr('aria-expanded', 'false');
        element.siblings().find('[aria-hidden=false]').attr('aria-hidden', 'true');
    }

    function accessibility(button) {
        button.on('keydown', function(e) {
            if(e.which === 32) {
                //space bar
                e.preventDefault();
                toggleDropdown(button.parent());
                button.next().focus();
            }

        });
    }

    function trackMenuClick(element) {

        let $link = element.find('.sw-mainnav__link[data-adobe-link-name]');
        
        if ($link.length === 1) {
            let adobeLinkName = $link.attr("data-adobe-link-name");
            if (typeof(_satellite) !== "undefined") {
                _satellite.track('Nav Click Event ' + adobeLinkName);
            }
        }
    }

    // global close all if clicked outside the super nav
    $(document).on('click', function(e) {
        if($(el) !== e.target && !$(el).has(e.target).length){
            if($(el).hasClass('dropdown-open')) {
                closeDropdown($(el));
            }
        }
    });

    //escape
    $(document).on('keydown', function(e) {
        if($(el).hasClass('dropdown-open') && e.keyCode === 27) {
            closeDropdown($(el));
        }
     });
}
