import $ from "jquery";
import "../custom-vendor/slick-carousel/slick/slick";

export class ImageSlider {
  constructor($el, slideSelector, conf) {
    this.$el = $el;
    this.$hero = $el.parents(".js-sw-hero");
    this.$pauseBtn = this.$hero.find(".js-sw-hero__pause");
    this.pauseClass = "paused";
    this.isCrossfade = this.$hero.hasClass("sw-hero--crossfade");
    this.slickParams = this.isCrossfade
      ? this.slickStaticCta()
      : this.slickDefaults();
    let slickConf = Object.assign(this.slickParams, conf);
    //get initial slide interval, or use the default if none found
    let firstSlide = $el.find(slideSelector).first();

    var initialSlideInterval = firstSlide.data("interval");
    if (
      typeof initialSlideInterval !== "undefined" &&
      parseInt(initialSlideInterval) !== NaN
    ) {
      slickConf.autoplaySpeed = initialSlideInterval;
    }        

    this.setOutline(firstSlide.data("outline"));

    // bind event prior to slick init
    this.bindEvents();   

    // initialize slick 
    this.$el.slick(slickConf);    
    
  }

  bindEvents() {
    // save the content 
    let thisClass =this;
    
    //on init, display pause button
    this.$el.on("init", function(slick) {
      let $slickDots = $(this).parents(".js-sw-hero").find(".slick-dots");
      let $pauseButton = $(this).siblings(".sw-hero__controls").find(".js-sw-hero__pause");
            
        
      if ($slickDots.length) {
        $pauseButton.appendTo($slickDots)
      }
      $pauseButton.attr("aria-hidden", "false");
      
      // Execure right after the init is completed
      setTimeout(function () {
        if (window.innerWidth <= 768) { //mobile view
          if (document.body.classList.contains('is-homepage')) {
            thisClass.stopSlider();
            thisClass.mobileStop = true;
          }
        }
       }, 0);
    });

    this.$el.on("afterChange", (event, slick, currentSlide, nextSlide) => {
      if (currentSlide === 0) { // we got back to the first slide regardless of how we got here
        this.stopSlider();
      }

      if (thisClass.isCrossfade) {
        // in slideshow mode there is nothing to tab to
        var slides = $(slick.$slides.get(currentSlide));
        if (slides.length > 0) {
          slides[0].tabIndex = -1;
        }
      }

      this.setOutline($(slick.$slides.get(currentSlide)).data("outline"));
      

      //if the current slide has a duration defined in its markup, set the autoplayspeed to that duration value
      let currentSlideInterval = $(slick.$slides.get(currentSlide)).data(
          "interval");

      if (
        typeof currentSlideInterval !== "undefined" &&
        parseInt(currentSlideInterval) !== NaN
      ) {
        thisClass.$el.slick(
          "slickSetOption",
          "autoplaySpeed",
          currentSlideInterval,
          false
        );
      }


    }); 

    // bind an event on the arrows to de-focus them when
    // you mouse out, so the focus state doesn't get stuck          
    this.$el.on("mouseleave", ".slick-next, .slick-prev", event => {
      event.currentTarget.blur();
    });
    this.$el.on("swipe", () => this.stopSlider());

    this.$el.on("click", ".slick-dots", () => this.stopSlider());
    this.$el.on("click", ".slick-arrow", () => this.stopSlider());

    this.$pauseBtn.click(event => this.toggleSlider(event));

    window.addEventListener('scroll', function () {
      if (window.pageYOffset >= 0 && thisClass.mobileStop) {
        thisClass.mobileStop = false;            
        thisClass.toggleSlider();
      }
    });
  }

  slickDefaults() {
    return {
      dots: true,
      adaptiveHeight: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnDotsHover: true
    };
  }
  slickStaticCta() {
    return {
      draggable: true,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      dots: false,
      adaptiveHeight: true,
      fade: true,
      speed: 600,
      infinite: true,
      cssEase: "ease-in-out",
      slidesToShow: 1,
      slidesToScroll: 1
    };
  }
  
  toggleSlider() {
    //play
    if (this.$hero.hasClass(this.pauseClass)) {
      this.$el.slick("slickPlay");
      this.$hero.removeClass(this.pauseClass);
      this.toggleButtonAccessibility("play");
    } else {
      //pause
      this.$hero.addClass(this.pauseClass);
      this.$el.slick("slickPause");
      this.toggleButtonAccessibility("pause");
    }
    return false;
  }

  stopSlider() {
    if (!this.$hero.hasClass(this.pauseClass)) {
      this.$hero.addClass(this.pauseClass);
      this.$el.slick("slickPause");
      this.toggleButtonAccessibility("pause");
    }
    return false;
  }

  setOutline(currentOutlineStyle) {    
      let control = this.$el

      // remove all outline classes
      control.attr('class', function (i, c) {
          return c && c.replace(/(^|\s)outline-\S+/g, '');
      });

      if (typeof currentOutlineStyle !== "undefined") {
          control.addClass(currentOutlineStyle);
      }
  }
  toggleButtonAccessibility(buttonState) {
    var $playText = this.$pauseBtn.find('.js-sw-hero__pause__play-text');
    var $pauseText = this.$pauseBtn.find('.js-sw-hero__pause__pause-text');
    switch (buttonState) {
      case "play":
        this.$pauseBtn.attr('aria-pressed', "false"); //toggle the pressed nature of the button.  false = pause is showing (after click)
        $playText.attr("aria-hidden", "true");        //hide play text to screen readers
        $pauseText.attr("aria-hidden", "false");      //show pause text to screen readers
        break;
      case "pause":
      default:
        this.$pauseBtn.attr('aria-pressed', "true");  //toggle the pressed nature of the button.  true = play is showing (after click)
        $playText.attr("aria-hidden", "false");       //show play text to screen readers
        $pauseText.attr("aria-hidden", "true");       //hide pause text to screen readers
        break;
    }
  }
}
