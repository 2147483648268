import $ from 'jquery';
import getMobileOperatingSystem from '../helpers/determineMobileOS.js';

export default function getTheApp() {
    let $appStoreContainer = $('.sw-getapp-link--appstore'),
        $googlePlayContainer = $('.sw-getapp-link--googleplay');

    if(getMobileOperatingSystem() === 'iOS' || getMobileOperatingSystem() === 'Android') {

        if(getMobileOperatingSystem() === 'iOS' && !$appStoreContainer.hasClass('should-show')) {
            $appStoreContainer.addClass('should-show');
        } else {
            $appStoreContainer.removeClass('should-show');
        }

        if(getMobileOperatingSystem() === 'Android' && !$googlePlayContainer.hasClass('should-show')) {
            $googlePlayContainer.addClass('should-show');
        } else {
            $googlePlayContainer.removeClass('should-show');
        }

    }
}