import $ from "jquery";
export default function stopPageJumpForHashUrl() {
    var scrollTopPos;
    window.onload = function() {
        scrollTopPos = window.scrollY;
    }

    window.onscroll = function() {
        scrollTopPos = window.scrollY;
    }
    $('[role=tab]').on('click', function(e) {
        window.location.hash = $(this).attr('href');
        window.scrollTo({left: 0, top: scrollTopPos, behavior: 'smooth'});
        return false;
    });
}
