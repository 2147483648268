import $ from 'jquery';

export default function flipper() {
    let $flipper = $('.js-sw-cards__item');

    if($flipper.length) {
        $flipper.focusin(function(){
            $(this).addClass('is-focused');
        }).focusout(function(){
            $(this).removeClass('is-focused');
        });
    }
}