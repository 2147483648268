import $ from 'jquery';

export default function moveItems() {
    var mediaQueryList = window.matchMedia("(min-width: 1024px)");
    function handleScrenResize(mql) {
        let $nav = $('.js-sw-mainnav');
        let $hamburger = $('.js-sw-hamburger');
        let $header = $('.sw-header');

        if (mql.matches) {
            // Desktop
            if($('.js-sw-eyebrow__language-contrast').length) {
                $('.js-sw-eyebrow__language-contrast').prependTo('.js-sw-eyebrow');
            }

            if($('.js-sw-eyebrow__navitem--authentication').length) {
                $('.js-sw-eyebrow__navitem--authentication').appendTo('.sw-eyebrow__nav');
            }

            if ($nav.length) {
                $nav.appendTo($header);
            }
            
        } else {
            // Tablet / Mobile
            if($('.js-sw-eyebrow__language-contrast').length) {
                $('.js-sw-eyebrow__language-contrast').prependTo('.js-sw-mainnav');
            }

            if($('.js-sw-eyebrow__navitem--authentication').length) {
                $('.js-sw-eyebrow__navitem--authentication').appendTo('.js-sw-userpane-mobile');
            }

            if ($nav.length) {
                $nav.insertAfter($hamburger);
            }
        }
    }
    mediaQueryList.addListener(handleScrenResize);
    handleScrenResize(mediaQueryList);
};