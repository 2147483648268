import $ from "jquery";
import ally from 'ally.js/ally';
export class OpenModal {
  constructor($el) {
    $(`<div class="sw-modal__overlay"></div>`).appendTo("body");
    this.$el = $el;

    const modalId = "sw-modal__" + this.$el.attr('id');
    
    this.$modal = this.$el.parents(".js-sw-video__overlay").find(".js-sw-modal");
    this.$modal.clone().appendTo("body").attr("id",modalId)  //we're cloning it to be towards the bottom to account for z-index issues
    this.$modal.remove();
    this.$body = $("body");
    this.$newModal = $("#" + modalId);

    this.dialog =  this.$newModal[0];
    this.hiddenHandle;
    this.disabledHandle;
    
    this.$close = this.$newModal.find(".js-sw-modal__close__btn");
    this.tabs =  this.$newModal.find("select, input, textarea, button, a");
    this.firstTab = this.tabs.first();
    this.bindEvents();
  }
  bindEvents() {
    this.$el.click(e => this.toggleModal(e));
    this.$close.click(e => this.toggleModal(e));
  }
  checkCloseDialog(e) {
    if (e.keyCode == 27 && this.$body.hasClass("modal-open-sw-v2")) {
      //The video player, and possibly other classes, add events to the close button.  We need to make sure that the keypress close also fires these events.
      $('.sw-modal-open-sesame').find('.js-sw-modal__close__btn').trigger('click');
    }
  }

  toggleModal(e) {
    if (this.$body.hasClass("modal-open-sw-v2")) {
      //close the dialog
      // undo hiding elements outside of the dialog
      this.hiddenHandle.disengage();
      // undo disabling elements outside of the dialog
      this.disabledHandle.disengage();

      this.$body.removeClass("modal-open-sw-v2");
      this.$el.focus();

      $(e.currentTarget).closest('.sw-modal').removeClass('sw-modal-open-sesame');
      document.removeEventListener("keydown", e => this.checkCloseDialog(e));
    }
    else {
      //open the dialog
      // Make sure that no element outside of the dialog
      // can be interacted with while the dialog is visible.
      // This means we don't have to handle Tab and Shift+Tab,
      // but can defer that to the browser's internal handling.
      this.disabledHandle = ally.maintain.disabled({
        filter: this.dialog,
      });

      // Make sure that no element outside of the dialog
      // is exposed via the Accessibility Tree, to prevent
      // screen readers from navigating to content it shouldn't
      // be seeing while the dialog is open. See example:
      // https://marcysutton.com/slides/mobile-a11y-seattlejs/#/36
      this.hiddenHandle = ally.maintain.hidden({
        filter: this.dialog,
      });

      //get the modal window thinger, and mark it


      $('#sw-modal__' + e.currentTarget.id).addClass('sw-modal-open-sesame');
      this.$el.blur();
      this.$body.addClass("modal-open-sw-v2");

      window.setTimeout(() => this.firstTab.focus(), 505); //we add a timer to account for the css animation that delays this element being focusable
      document.addEventListener("keydown", e => this.checkCloseDialog(e));
    }

    return false;
  }
}
