import $ from "jquery";
import "core-js/fn/object";
import Youtube from "youtube-player";
export class VideoPlayer {
  constructor($el) {
    this.$el = $el;
    //this is the play button for the "inline"/ overlay video player.  It is not used for the modal player.
    // only grab the button for this player.  There may be other players on the page, and we do not want this button to open them!
    this.$playButton = this.$el.siblings('.sw-video__content').find(".js-sw-video__play__button");
    this.player = {};
    this.videoMountPoint = $el.attr("data-videoid");
    this.$inline = this.$el.parents(".js-sw-video--inline")
      ? this.$el.parents(".js-sw-video--inline")
      : null;
    //this is super hacky, but since we had to clone the modal, we can no longer rely on dom structure. We now check and see if the modal exists and check to make sure the current video isn't an inline video so as to not add any additional css.
    this.$modal = this.$inline.length <= 0 &&  $(".js-sw-video--modal").length > 0
      ? $(".js-sw-video--modal")
      : null;
    this.initialize();
    this.bindEvents();
  }
  bindEvents() {
    this.$playButton.click(e => this.playVideo(e));
    //modal close should stop the video from playing
    if (this.$modal && this.$modal.length) {
      $("#sw-modal") 
        .find(".js-sw-modal__close__btn")
        .click(e => this.stopVideo(e));
    } 
    //inline/overlay video close should also stop the video from playing
    if (this.$inline && this.$inline.length) {
      this.$inline
        .find(".js-sw-video__close__btn")
        .click(e => this.stopVideo(e));
    }
  }
  initialize() {
    this.player = Youtube(this.videoMountPoint, {
      videoId: this.videoMountPoint,
      playerVars: {
        rel: 0
      }
    });
    this.player.on("stateChange", event => this.handleYoutubeState(event));
  }
  //play and stop CSS class changes, for inline/overlay player only
  playCSS() {
    this.$inline.find(".sw-video__overlay").css("visibility", "hidden");
    this.$inline.find(".sw-video__photo").css("visibility", "hidden");
    this.$inline.css("z-index", "5");
    this.$el.css("visibility", "visible");
  }
  stopCSS() {
    this.$inline.find(".sw-video__overlay").css("visibility", "visible");
    this.$inline.find(".sw-video__photo").css("visibility", "visible");
    this.$el.css("visibility", "hidden");
    this.$inline.css("z-index", "1");
  }
  handleYoutubeState(event) {
    /*
      State codes are:
      -1 (unstarted)
      0 (ended)
      1 (playing)
      2 (paused)
      3 (buffering)
      5 (video cued).
    */
    if (event.data === 0) {
      if (this.$inline.length) {
        this.stopCSS();
      }
    }
  }

  playVideo() {
    this.player.playVideo();
    if (this.$inline.length) {
      window.setTimeout(() => this.playCSS(), 395);
    }
    return false;
  }
  stopVideo() {
    this.player.stopVideo();
    if (this.$inline.length) {
      this.stopCSS()
    }
    return false;
  }
}
