export default function backToTopButton() {
  let $button = document.querySelectorAll(".js-sw-goto-top");

  for (let i = 0; i < $button.length; i++) {
    $button[i].onclick = function(e) {
      e.preventDefault();
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" });

      var skipToContent = document.getElementsByClassName("sw-skip-to-content")[0];

       if( $('.js-sw-hamburger').is(':visible')){
            $('.js-sw-hamburger').focus();
        }       

      if(typeof skipToContent !== "undefined") {
 
        skipToContent.focus();
        skipToContent.blur();
      }
      
    };
  }
}
