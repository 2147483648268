import $ from 'jquery';
import dialogManager from "./dialogManager.js";
import ally from 'ally.js/ally';


export default function openHomePageModal(){

    var modalCookie = getCookie("modalCookie");

    if(modalCookie == "")
    {
        var dialog = new dialogManager();
        var modalDiv = $('.homePageModal');
        
        if(modalDiv.length == 0){
          
            window.setTimeout(function(){
                openHomePageModal(), 100;
            });
        
          return;
        }

        $(".homePageModal").addClass("visible");

        $(".homePageModal").attr("role","dialog");



        dialog.createDialog(modalDiv[0]);
        $("body").addClass("lockModal");

        dialog.afterRemove(function(){
            $(".homePageModal").removeClass("visible");
            $("body").removeClass("lockModal");
        }
        );
        
        $("div.homePageModal div.closeButtonDiv input.modalCloseButton").click(function(){
          dialog.removeModal();  
        })
       
        setCookie("modalCookie","interstitial", 1); 

     
    }
    else{
        
    }

}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }