﻿import $ from 'jquery';
export default function dialogManager() {

    // Variable for storing the last focused element
    var _lastFocusedElement;
    var _container = null;
    var _focusableElements=[];
    var _docFocusableElements=[];
    var _firstTabStop = null;
    var _lastTabStop = null;
    var _currentElementIndex = 0;
    var _lastGoodStop = null;
    var _afterRemoveCallback = null;

    this.removeModal = function(){
        removeModalInternal();
    }


function removeModalInternal(){
    if (_container === null) return;

        document.removeEventListener('keydown', listenToTab);
        _lastFocusedElement.focus();
        if(_afterRemoveCallback!==null){
            try{
                _afterRemoveCallback();
            }
            catch(e){
                //empty suppress
            }
        }
}

    function listenToTab(e){
        // Listen for the Tab key
        if (e.keyCode === 9) {
            // If Shift + Tab
            if (e.shiftKey) {
                // If the current element in focus is the first focusable element within the modal window...
                do{
                    _currentElementIndex--;
                    if(_currentElementIndex < 0){
                        _currentElementIndex=_focusableElements.length-1;
                    }
                } 
                while(!$(_focusableElements[_currentElementIndex]).is(":visible") || $(_focusableElements[_currentElementIndex]).css("visibility")==="hidden");
                _focusableElements[_currentElementIndex].focus();
                // if Tab
            } else {
                e.preventDefault();
                //there should be at least one tabbable element in the list or infinite loop will happen
                do{
                    _currentElementIndex++;
                    if(_currentElementIndex>=_focusableElements.length){
                        _currentElementIndex=0;
                    }
                } 
                while(!$(_focusableElements[_currentElementIndex]).is(":visible") || $(_focusableElements[_currentElementIndex]).css("visibility")==="hidden");

                _focusableElements[_currentElementIndex].focus();
            }
        }

        // Close the window by pressing the Esc-key
        if (e.keyCode === 27) {
            removeModalInternal();
        }
    }

    


    this.createDialog=function(container) {
        // Close all open modal windows
        this.removeModal();
        _focusableElements = [];
        _docFocusableElements = [];
        // Store the last focused element
        _lastFocusedElement = document.activeElement;
        //keep a reference to this modal
        _container = container;
        // Select the modal window
        var modal = container;        

        if(typeof modal === "undefined" || modal === null){
            return;
        }

        // Find all focusable children
        var focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
        _focusableElements = modal.querySelectorAll(focusableElementsSelector); 

        _focusableElements = Array.prototype.slice.call(_focusableElements);
        // Focus the window
        _focusableElements[0].focus();        

        _currentElementIndex = 0;

         // Add keydown event
        // document.addEventListener('keydown', function (e) {
        
        // });
        document.addEventListener('keydown', listenToTab);
            try{
                $(document).swipe( {
                    
                //Generic swipe handler for all directions
                swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
                    if(direction !=="right" && direction !=="left") return;
 
                    if (direction === "right") {
                        do{
                            _currentElementIndex++;
                            if(_currentElementIndex>=_focusableElements.length){
                                _currentElementIndex=0;
                            }
                        } 
                        while(!$(_focusableElements[_currentElementIndex]).is(":visible") || $(_focusableElements[_currentElementIndex]).css("visibility")==="hidden");
                        _focusableElements[_currentElementIndex].focus();
                    }
                    else if (direction === "left") {
                        do{
                            _currentElementIndex--;
                            if(_currentElementIndex < 0){
                                _currentElementIndex=_focusableElements.length-1;
                            }
                        } 
                        while(!$(_focusableElements[_currentElementIndex]).is(":visible") || $(_focusableElements[_currentElementIndex]).css("visibility")==="hidden");
                        _focusableElements[_currentElementIndex].focus();
                    }
                } 
                    
                
                });
            
                //Set options
                $(document).swipe( {fingers:1} );
            }
            catch(err)
            {
                //alert(err.message);
            }

          
    addListenerToDocument();
        
    }

    this.afterRemove=function(callback){
        _afterRemoveCallback = callback;
    }

    this.addSingleItem = function(item){
        _focusableElements.push(item);
    }
    // Remove the modal window if it's visible
    

    this.setInitialFocus = function(elem){
        var i;
        for(i=0;i<_focusableElements.length;i++){
            if(_focusableElements[i] == elem && elem !== undefined){
                _currentElementIndex = i;
                elem.focus();
                break;
            }
        }

    }

    

    function addListenerToDocument(){
                
        var docFocusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
        _docFocusableElements = document.querySelectorAll(docFocusableElementsSelector);

        $.each(_docFocusableElements, function(index, value){        
           _docFocusableElements[index].addEventListener('focus', focusCallBack);
        });
    }

    function focusCallBack(event){

        try{        
            var found = false;
            $.each(_focusableElements, function(index, elem){
                if(document.activeElement == elem){
                    found = true;
                    _lastGoodStop = elem;
                    return;
                }

            }) ;
            
            if(!found)
            {
                if(_lastGoodStop != null){

                    if(_lastGoodStop == _focusableElements[0])  {
                        _focusableElements[_focusableElements.length-1].focus();
                    
                    } else{
                        _focusableElements[0].focus();}                
                }
            }
    }
    catch(ex){
        //alert(ex.message);
    }
    }
    
};

//export {dialogManager}
 

