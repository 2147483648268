import $ from 'jquery';
import dialogManager from "./dialogManager.js";


export default function mobileNav(el) {
    let $headerPane = $('.sw-headerpane');

    $(document).keydown(function (e) {
        if (e.keyCode === 27) {
            hideMobileNav();
            $(el).attr("aria-expanded", "false");
        }
    });

    //window.addEventListener('keydown', function (e) {
    //    if (window.origin != 'https://' + defaultOrigin) {
    //        return;
    //    }
    //    else {
    //        //escape
    //        if (e.keyCode === 27) {
    //            hideMobileNav();
    //            $(el).attr("aria-expanded", "false");
    //        }
    //    }
    //});

    $(el).on('click', function (e) {
        e.preventDefault();
        toggleMobileNav();
    });

    function mobileNavScreenTop() {
        let topPos = $headerPane.outerHeight();
        if (topPos > 0) {
            $('.js-sw-mainnav').css('top', topPos);
        }
    }
    var dialog;
    function toggleMobileNav() {
        if ($('body').hasClass('mobile-nav-open')) {
            hideMobileNav();
            if (typeof dialog !== 'undefined') dialog.removeModal();
            $(el).attr("aria-expanded", "false");

        } else {
            showMobileNav();
            $(el).attr("aria-expanded", "true");

            dialog = new dialogManager();
            var modal = $('nav.sw-mainnav.js-sw-mainnav');

            dialog.createDialog(modal[0]);
            dialog.addSingleItem($("button.sw-hamburger")[0]);
            dialog.addSingleItem($('a.sw-logo')[0]);

            var login = $('button.js-sw-eyebrow__navlink--userloginsignup')[0];
            var loggedIn = $('button.js-sw-eyebrow__navlink--user')[0];

            if (login != null) { dialog.addSingleItem(login); }
            if (loggedIn != null) { dialog.addSingleItem(loggedIn); }

            stopAllSliders();
        }
    }

    function showMobileNav() {
        $('body').addClass('mobile-nav-open');
        mobileNavScreenTop();
    }

    function hideMobileNav() {
        $('body').removeClass('mobile-nav-open');
    }

    window.onresize = function () {
        if (window.offsetWidth < '1024px') {
            mobileNavScreenTop();
        } else {
            $('.js-sw-mainnav').attr('style', '');
            if ($('body').hasClass('mobile-nav-open')) {
                hideMobileNav();
            }
        }
    }
};