import $ from "jquery";
import "../custom-vendor/jquery-touchswipe";
import "../custom-vendor/bootstrap-carousel/carousel";

export default function downloadAppSwipe() {
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains 'Android'
    if (/windows phone/i.test(userAgent)) {
      $("#appStore").css("display", "none");
      $("#appleTC").css("display", "none");
    }
    if (/android/i.test(userAgent)) {
      $("#appStore").css("display", "none");
      $("#appleTC").css("display", "none");
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      $("#googlePlay").css("display", "none");
      $("#googleTC").css("display", "none");
    }
    return false;
  }

  $(function() {
    getMobileOperatingSystem();
  });

  function activateFeatureBlock() {
    $(".more-features-left-div > div, .more-features-right-div > div").on(
      "click",
      e => changeSlideClick(e)
    );
    $(
      ".more-features-left-div > div > button, .more-features-right-div > div > button"
    ).on("click keydown", e => changeSlideClick(e));
  }

  function enableCarouselSwipe() {
    /* dependency: https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js
       reference: http://labs.rampinteractive.co.uk/touchSwipe/docs/index.html */
    //  if($("#see-more-great-features").length > 0){
    $(".carousel").swipe({
      swipe: function(
        event,
        direction,
        distance,
        duration,
        fingerCount,
        fingerData
      ) {
        if (direction == "left") {
          $(this).carousel("next");
        }
        if (direction == "right") {
          $(this).carousel("prev");
        }
      },
      allowPageScroll: "vertical"
    });
    // }
  }

  $(function(e) {
    activateFeatureBlock();
    enableCarouselSwipe();
  });
}
function changeSlideClick(e) {
  if (e.type == "click" || e.keyCode == "32") {
    let phoneImage = $(".more-features-center-div > .phone-frame > img");
    let $previousActiveElement, $previousActiveElementButton, previousActiveIconClass, iconClass, blockClass, $activeElement, $activeElementButton;

    
    if ($(e.currentTarget).is("button")) {
      //account for tabs
      e.preventDefault();
      // call custom function here
      $activeElement = $(e.currentTarget).parent();
      $activeElementButton = $(e.currentTarget);
    } else {
      $activeElement = $(e.currentTarget);
      $activeElementButton = $(e.currentTarget).find("button");
    }

    // when a feature block is clicked,
    // reset previous active block to its default state...
    $previousActiveElement = $("#app-landing-more-features").find(
      ".more-features-active"
    ); // order-ahead-block is initial active element
    $previousActiveElementButton = $previousActiveElement.find("button");
    previousActiveIconClass = $previousActiveElement
      .find("div.app-landing-sprites")
      .attr("class")
      .split(" ")[1];

    $previousActiveElement.removeClass("more-features-active");
    $previousActiveElement
      .find("div.app-landing-sprites")
      .addClass(previousActiveIconClass.substr(7)) // add non-active icon class
      .removeClass(previousActiveIconClass); // remove icon active class

    // Ada remove the selected and the title 
    $previousActiveElementButton.removeAttr("title").attr("aria-selected", "false");

    // ...and add active classes to current active block
    iconClass = $activeElement
      .find("div.app-landing-sprites")
      .attr("class")
      .split(" ")[1];
    blockClass = $activeElement.attr("class").split(" ")[0];

    $activeElement
      .find("div.app-landing-sprites")
      .addClass("active-" + iconClass)
      .removeClass(iconClass);
    phoneImage.attr({
      src:
        "~/media/_SubwayV2/DownloadTheApp/" +
        iconClass.slice(0, -5).toUpperCase() +
        ".png",
      alt: ""
    }); // set image corresponding to active block

    $activeElement
      .toggleClass(blockClass) // more-features-active class must be before blockClass
      .addClass("more-features-active")
      .toggleClass(blockClass);

    $activeElementButton.attr("title", "selected").attr("aria-selected", "true");
    return false;
  }
}
