import $ from 'jquery';

export default function signup(selector) {
    let $signupContainer = selector,
        $signupInput = $signupContainer.find('.sw-signup__field'),
        $signupLabel = $signupContainer.find('.sw-signup__label'),
        $signupButton = $signupContainer.find('.sw-signup__button'),
        $signupDisclaimer = $signupContainer.find('.sw-signup__disclaimer'),
        $signupError = $signupContainer.find('.sw-signup__error'),
        $signupErrorEmail = $signupContainer.find('.sw-signup__error-email'),
        $signupErrorPhone = $signupContainer.find('.sw-signup__error-phone'),
        $signupMessageHolder = $signupContainer.find('.sw-signup__message'),
        messageSuccess = $signupContainer.data('msg-success'),
        hasInputBeenFocused = false,
        keycodes = {ENTER:13 };

        var emailReg = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        var phoneReg = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

    $signupInput.on('focus', function(e){
        $(this).attr('placeholder','');
        $signupLabel.removeClass('visuallyhidden');
        $signupDisclaimer.addClass('is-shown');
        hasInputBeenFocused = true;
    }).on('focusout', function(){
        validateInputField();        
    }).on('keydown', function(e) {
        if(e.keyCode === keycodes.ENTER){
            $signupButton.trigger('click');
        }
    });

    $signupButton.on('click', function(e) {
        $signupInput.attr('placeholder', $signupInput.attr('aria-label'));
        $signupLabel.addClass('visuallyhidden');
        $signupErrorEmail.removeClass('error-shown');
        $signupErrorPhone.removeClass('error-shown');
        $signupInput.attr('aria-describedby', "sw-signup__disclaimer");
        hasInputBeenFocused = false;
        
        if(validateInputField() === true) {
            submitUserInput();
            $signupDisclaimer.removeClass('is-shown');
        }
    });
	
	function submitUserInput() {
		var userText = $signupInput.val();
        var jsonData = {};
        jsonData["subscribeValue"] = userText;

        $.ajax({
            type: "POST",
            url: $('body').data('signup-end-point'),
            data: JSON.stringify(jsonData),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function(response) {
                if(response.success === true) {
                    $signupContainer.addClass('has-succeeded');
                    $signupMessageHolder.html(messageSuccess);
                } else {
                    $signupContainer.removeClass('has-succeeded').addClass('has-failed')
                    $signupMessageHolder.html(response.errorMessage);
                }
            },
            error: function (e) {
                console.log('fail: ' + e);
            }
        });
	}

    function validateInputField() {
        //https://stackoverflow.com/questions/30213220/validate-phone-number-email-address-in-same-input-field
        if(emailReg.test($signupInput.val()))
        {
            $signupError.removeClass('error-shown');
            return true;
        }

        if(phoneReg.test($signupInput.val()))
        {
            $signupError.removeClass('error-shown');
            return true;
        }

        $signupError.addClass('error-shown');
        $signupInput.attr('aria-describedby', "sw-signup__error");
        return false;
    }

}