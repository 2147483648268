import $ from 'jquery';
import getMobileOperatingSystem from '../helpers/determineMobileOS.js';

export default function getTheApp() {
    let $getAppContainer = $('.js-sw-footer__getapp'),
        $appStoreContainer = $getAppContainer.find('.sw-footer__getapp-item--appstore'),
        $googlePlayContainer = $getAppContainer.find('.sw-footer__getapp-item--googleplay');

    if(getMobileOperatingSystem() === 'iOS' || getMobileOperatingSystem() === 'Android') {
        if(!$getAppContainer.hasClass('is-mobile-device')) {
            $getAppContainer.addClass('is-mobile-device');
        }

        if(getMobileOperatingSystem() === 'iOS' && !$appStoreContainer.hasClass('should-show')) {
            $appStoreContainer.addClass('should-show');
        } else {
            $appStoreContainer.removeClass('should-show');
        }

        if(getMobileOperatingSystem() === 'Android' && !$googlePlayContainer.hasClass('should-show')) {
            $googlePlayContainer.addClass('should-show');
        } else {
            $googlePlayContainer.removeClass('should-show');
        }

    } else {
        if($getAppContainer.hasClass('is-mobile-device')) {
            $getAppContainer.removeClass('is-mobile-device');
        }
    }
}