export class loadPress {
  constructor($el) {    
    this.$el = $el;
    this.pressItems = $(".js-sw-press__item");
    this.$el.on("click", () => this.getNextPage());
  }
  getNextPage() {
    let that = this.$el;
    var hiddenDivs = this.pressItems.filter(function() {
      return $(this).css("display") == "none";
    });

    if (hiddenDivs.length > 0) {
      hiddenDivs.slice(0, 6).each(function(i) {
        $(this).css("display", "block");
        if (i == 0) {
          $(this)
            .find("a")
            .focus();
        }
      });

      if (hiddenDivs.length <= 6) {
        setTimeout(()=> {
          that.css("display", "none")
          return false;
        },0);      
      }
    }
   
    return false;
  }
}
