export class bgVideo {
  constructor($el) {
    this.$el = $el;
    this.$video = this.$el.find("video");
    this.$videoButton = this.$el.find(".js-sw-video__btn");
    this.$play = this.$videoButton.find(".sw-video__play");
    this.$pause = this.$videoButton.find(".sw-video__pause");
    this.$video.removeAttr("controls");
    this.$videoButton.on("click", () => this.playPauseMedia());
  }
  playPauseMedia() {
    if (this.$video.get(0).paused) {
      this.$play.css("display", "none");
      this.$pause.css("display", "block");
      this.$video.get(0).play();
      this.toggleButtonAccessibility("play");
      return false;
    } else {
      this.$play.css("display", "block");
      this.$pause.css("display", "none");
      this.$video.get(0).pause();
      this.toggleButtonAccessibility("pause");
      return false;
    }
  }

  toggleButtonAccessibility(buttonState) {
    var $playText = this.$videoButton.find('.js-sw-video__play-text');
    var $pauseText = this.$videoButton.find('.js-sw-video__pause-text');
    switch (buttonState) {
      case "play":
        this.$videoButton.attr('aria-pressed', "false"); //toggle the pressed nature of the button.  false = pause is showing (after click)
        $playText.attr("aria-hidden", "true");        //hide play text to screen readers
        $pauseText.attr("aria-hidden", "false");      //show pause text to screen readers
        break;
      case "pause":
      default:
        this.$videoButton.attr('aria-pressed', "true");  //toggle the pressed nature of the button.  true = play is showing (after click)
        $playText.attr("aria-hidden", "false");       //show play text to screen readers
        $pauseText.attr("aria-hidden", "true");       //hide pause text to screen readers
        break;
    }
  }
}
