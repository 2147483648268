﻿import $ from 'jquery';
import getMobileOperatingSystem from '../helpers/determineMobileOS.js';

export default function getTheAppBanner() {
    
    let $getAppContainerTop = $('.sw-hero__copy div.sw-mobile-app');
    
    var iosLinkTop = $getAppContainerTop.find("span.sw-mobile-app__link:nth-child(2)");    
    var androidLinkTop = $getAppContainerTop.find("span.sw-mobile-app__link:nth-child(3)");

    let $getAppContainerBottom = $('div.sw-1up div.sw-mobile-app');
    var iosLinkBottom = $getAppContainerBottom.find("span.sw-mobile-app__link:nth-child(1)");    
    var androidLinkBottom = $getAppContainerBottom.find("span.sw-mobile-app__link:nth-child(2)");

    if(getMobileOperatingSystem() === 'iOS'){
        $(androidLinkTop).hide();
        $(androidLinkBottom).hide();
    }
    if(getMobileOperatingSystem() === 'Android'){
        $(iosLinkTop).hide();
        $(iosLinkBottom).hide();
    }

 
}